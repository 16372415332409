@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Courgette', cursive;
  font-family: 'Roboto Flex', sans-serif;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-snap-type: y mandatory; 
  scroll-behavior: smooth; 

} 



code {
  font-family: 'Courgette', cursive;
  font-family: 'Roboto Flex', sans-serif;
}


.app{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}